import React from "react"
import "./aboutme.sass"
import aboutImg from "../../../static/images/profile-photo.png"

const AboutMe = () => (
    <div className="aboutme">
      <div className="aboutme__wrapper">
        <div className="aboutme__image-wrap">
          <img className="aboutme__image" alt="foto de perfil" src={aboutImg}></img>
        </div>
        <div className="aboutme__content">
          <h3 className="aboutme__title">Perfil Profesional</h3>
          <p  className="aboutme__text"> Ginecóloga de la Universidad El Bosque, con 14 años de experiencia profesional,
          respaldada por actividad en consulta privada y vinculación a Medicina prepagada,
          Planes de Atención Complementaria, EPS y Hospitales del Distrito.<br></br><br></br>
          
          Actualmente prestando servicios en el consultorio privado de: consulta de ginecología,
          asesoría en planificación familiar (colocación de dispositivo intrauterino sencillo,
          dispositivo intrauterino hormonal, método intradermico, presentación de los diferentes
          anticonceptivos orales determinando el decuado para cada paciente, etc.) Consulta de
          prevención de cáncer de cuello uterino (patología cervical y colposcopia)</p>
        </div>
      </div>
    </div>
)
export default AboutMe
