import React from "react"
import "./clientslider.css"

class ClientSlider extends React.Component{

    arrayClients = [
        {author:"Ana Molina", comment:"La Dra. Patricia Gómez, es muy querida, muy humana y su atención es excelente"},
        {author:"Paola", comment:"Es muy cumplida y me atendieron con respeto y cuidado, lo que es muy difícil con otros ginecólogos"},
        {author:"Joissy Nieto", comment:"Gran profesional, honesta en sus dictámenes y respetusa del tiempo de sus pacientes"}
    ]
    currentIndex = 0
    state = {
        currentClient: this.arrayClients[0],
    }

	componentDidMount() {
        this.startSlide()
    }

    startSlide(){
        window.setInterval(()=>{
            this.currentIndex = this.getNextIndex()
            this.changeCurrentClient(this.currentIndex)
        },2500)
    }

    changeCurrentClient(index){
        this.setState({
            currentClient : this.arrayClients[index]
        })
    }

    getNextIndex(){
        return (this.currentIndex+1)%(this.arrayClients.length);
    }

    render() {
        return(
        <div className="clientslider">
            <div className="clientslider__wrapper">
                <h3 className="clientslider__title">Nuestros clientes</h3>
                <div className="clientslider__comment clientslider__fadein-animation">{this.state.currentClient.comment}</div>
                <div className="clientslider__triangle clientslider__fadein-animation"></div>
                <div className="clientslider__author clientslider__fadein-animation"> {this.state.currentClient.author}</div>
            </div>
        </div>
        )
    }
}
export default ClientSlider
