import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import AboutMe from "../components/aboutme/aboutme"
import ClientSlider from "../components/clientslider/clientslider"
import ScheduleDate from "../components/scheduledate/scheduledate"

import "./body.sass"

const IndexPage = () => (
  <Layout>
    <SEO lang="es" description="Doctora experta en ginecologia" title="Home" />
    <AboutMe></AboutMe>
    <ClientSlider></ClientSlider>
    <ScheduleDate></ScheduleDate>
  </Layout>
)

export default IndexPage
